<template>
  <div class='page-box'>
    <BaseSubTitle title="店铺地址"/>
    <div>
      <a-button v-pms="'add'" type='primary' icon='plus' @click='addAddress'>新增地址</a-button>
    </div>
    <base-table
      bordered
      :columns="columns"
      :dataSource="dataRef"
      :pagination="pageRtv"
      :rowKey="record => record.shopAddrId"
      :loading="loadingRef"
      @change="handleTableChange" >
      <template slot="addrType" slot-scope="text, record">
        <span v-if="record.addrType === 0">收货地址</span>
        <span v-if="record.addrType === 1">收票地址</span>
        <span v-if="record.addrType === 2">发货地址</span>
      </template>
      <template slot="defaultFlag" slot-scope="text, record">
        <span >{{record.defaultFlag?'是':'否'}}</span>
      </template>
      <div class='table-operations ' slot="operation" slot-scope="text, record">
        <a-button type='link' v-pms="'edit'" @click="editAddr(record.shopAddrId)">编辑</a-button>
        <a-popconfirm v-if="!record.officialFlag" placement="topRight" title="确定要删除？" ok-text="确定" cancel-text="取消" @confirm="()=>confirmDelete(record.shopAddrId)">
          <a-button type='link' v-pms="'delete'" :loading="shopAddrId===record.shopAddrId">删除</a-button>
        </a-popconfirm>
      </div>
    </base-table>
    <a-modal v-model="visible" okText='保存' :title="isAdd?'添加地址':'修改地址'" :confirmLoading='saveBtnLoading' @ok='submit'>
      <a-spin :spinning="modalLoading">
        <a-form-model
          ref="formRef"
          :model="formData"
          @submit.native.prevent
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="rules">
          <a-form-model-item label="联系人：" prop="linkName">
            <a-input  v-model="formData.linkName" allowClear placeholder="请填写" />
          </a-form-model-item>
          <a-form-model-item label="联系方式：" prop="linkPhone">
            <a-input  v-model="formData.linkPhone" allowClear placeholder="请填写" />
          </a-form-model-item>
          <a-form-model-item label="联系地址：" prop="regionCode">
            <a-cascader
              placeholder="请选择省市区"
              v-model="formData.regionCode"
              :options="regionOptions"
              @change="regionChange"
            />
          </a-form-model-item>
          <a-form-model-item label="详细地址：" prop="addr">
            <a-input placeholder="请输入详细地址" allowClear v-model="formData.addr" />
          </a-form-model-item>
          <a-form-model-item label="地址类型：" required>
            <a-radio-group v-model="formData.addrType">
              <a-radio :value="0">
                收货地址
              </a-radio>
              <a-radio :value="1">
                收票地址
              </a-radio>
              <a-radio :value="2">
                发货地址
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="默认地址：" required>
            <a-radio-group v-model="formData.defaultFlag">
              <a-radio :value="true">
                是
              </a-radio>
              <a-radio :value="false">
                否
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>
import { ref, onMounted, reactive, toRefs } from '@vue/composition-api'
import { common, shop } from '@/api'
import { phoneValidate } from '@/utils/validator'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import { useTableList } from '@/hooks'
export default {
  name: 'ShopAddress',
  setup (props, { root }) {
    const rules = {
      linkName: [
        { required: true, message: '请输入联系人姓名', trigger: 'change' },
        { max: 32, message: '您可以尝试一下简短的联系人姓名', trigger: 'change' }
      ],
      linkPhone: [
        { required: true, message: '请输入联系方式', trigger: 'change' },
        { validator: phoneValidate, trigger: 'change' }
      ],
      regionCode: [
        { required: true, message: '请选择联系地址', trigger: 'change' }
      ],
      addr: [
        { required: true, message: '请输入详细地址', trigger: 'change' },
        { max: 128, message: '您可以尝试一下简短的详细地址', trigger: 'change' }
      ]
    }
    const labelCol = { span: 4 }
    const wrapperCol = { span: 20 }
    const state = reactive({
      isAdd: true,
      visible: false,
      modalLoading: false,
      saveBtnLoading: false,
      shopAddrId: '',
      formRef: null,
      formData: {
        defaultFlag: true,
        addrType: 0,
        province: '',
        city: '',
        county: ''
      },
      regionOptions: []
    })
    const params = ref({
      shopId: getSession(SHOPID)
    })
    const columns = [
      {
        title: '联系人',
        dataIndex: 'linkName'

      }, {
        title: '联系方式',
        dataIndex: 'linkPhone'
      }, {
        title: '详细地址',
        dataIndex: 'addr'
      }, {
        title: '地址类型',
        dataIndex: 'addrType',
        scopedSlots: { customRender: 'addrType' }
      }, {
        title: '是否默认',
        dataIndex: 'defaultFlag',
        scopedSlots: { customRender: 'defaultFlag' }
      }, {
        title: '操作',
        width: 200,
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' }
      }
    ]
    function regionChange (e, selectedOptions) {
      if (selectedOptions) {
        state.formData.province = selectedOptions[0].label
        state.formData.city = selectedOptions[1].label
        state.formData.county = selectedOptions[2].label
      } else {
        state.formData.province = ''
        state.formData.city = ''
        state.formData.county = ''
      }
    }
    const { dataRef, pageRtv, loadingRef, setPage, refresh } = useTableList(shop.getAddrPage, params)
    function handleTableChange ({ current }) {
      setPage(current)
    }
    async function getRegionTree () {
      let { data, msg, code } = await common.getRegionTree()
      if (code === '00000') {
        state.regionOptions = handleTreeData(data)
      } else {
        root.$message.error(msg || '网络错误，请重试')
      }
    }
    function handleTreeData (data) {
      if (!data || !data.length) return
      for (let item of data) {
        item.value = item.regionCode
        item.label = item.regionName
        item.children = handleTreeData(item.children)
      }
      return data
    }
    function getRegionCodes (regionCode) {
      if (!regionCode || regionCode.length < 4) return []
      return [
        `${regionCode.substr(0, 2)}`,
        `${regionCode.substr(0, 4)}`,
        regionCode
      ]
    }
    function addAddress () {
      state.visible = true
      state.isAdd = true
      state.formData = {
        defaultFlag: true,
        addrType: 0
      }
    }
    function editAddr (id) {
      state.visible = true
      state.isAdd = false
      state.formData = {
        defaultFlag: true,
        addrType: 0
      }
      getAddrDetail(id)
    }
    async function getAddrDetail (id) {
      state.modalLoading = true
      let { code, data, msg } = await shop.getAddrDetail({
        shopAddrId: id
      })
      state.modalLoading = false
      if (code === '00000') {
        data.regionCode = getRegionCodes(data.regionCode)
        state.formData = data
      } else {
        root.$message.error(msg || '网络错误，请重试～')
      }
    }
    function submit () {
      state.formRef.validate(async valid => {
        if (valid) {
          let param = {
            shopId: getSession(SHOPID),
            ...state.formData
          }
          param.regionCode = state.formData.regionCode[2]
          state.saveBtnLoading = true
          let { code, msg } = await shop.saveShopAddr(param)
          state.saveBtnLoading = false
          state.visible = false
          if (code === '00000') {
            root.$message.success(state.isAdd ? '添加成功' : '修改成功')
            refresh()
          } else {
            root.$message.error(msg || state.isAdd ? '添加失败' : '修改失败')
          }
        }
      })
    }
    async function confirmDelete (id) {
      state.shopAddrId = id
      let { code, msg } = await shop.delShopAddr({
        shopAddrId: id
      })
      state.shopAddrId = ''
      if (code === '00000') {
        root.$message.success('删除成功')
        setPage(0)
      } else {
        root.$message.error(msg || '网络错误，请重试～')
      }
    }
    onMounted(() => {
      getRegionTree()
    })
    return {
      columns,
      regionChange,
      dataRef,
      pageRtv,
      loadingRef,
      setPage,
      refresh,
      rules,
      labelCol,
      wrapperCol,
      ...toRefs(state),
      getRegionTree,
      handleTreeData,
      getRegionCodes,
      submit,
      getAddrDetail,
      addAddress,
      editAddr,
      handleTableChange,
      confirmDelete

    }
  }
}
</script>
